import React from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider } from 'emotion-theming';
import theme from '../theme';
import styled, { keyframes } from 'styled-components';

import Footer from '../components/Footer';
import Reboot from '../style/reboot';
import Global from '../style/global';

const FadeOut = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

const Frame = styled.div`
  opacity: 0;
  animation: ${FadeOut} 0.6s 0.3s ease-in-out forwards;
`;

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Frame>
        <Reboot />
        <Global />

        {children}
        <Footer />
      </Frame>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
