// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogi-js": () => import("./../../../src/pages/blogi.js" /* webpackChunkName: "component---src-pages-blogi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sijainti-ja-palvelut-js": () => import("./../../../src/pages/sijainti-ja-palvelut.js" /* webpackChunkName: "component---src-pages-sijainti-ja-palvelut-js" */),
  "component---src-pages-talo-js": () => import("./../../../src/pages/talo.js" /* webpackChunkName: "component---src-pages-talo-js" */),
  "component---src-pages-tietosuojaseloste-js": () => import("./../../../src/pages/tietosuojaseloste.js" /* webpackChunkName: "component---src-pages-tietosuojaseloste-js" */),
  "component---src-pages-tilaratkaisu-js": () => import("./../../../src/pages/tilaratkaisu.js" /* webpackChunkName: "component---src-pages-tilaratkaisu-js" */),
  "component---src-pages-tyyli-ja-sisustus-js": () => import("./../../../src/pages/tyyli-ja-sisustus.js" /* webpackChunkName: "component---src-pages-tyyli-ja-sisustus-js" */),
  "component---src-pages-vuokralainen-js": () => import("./../../../src/pages/vuokralainen.js" /* webpackChunkName: "component---src-pages-vuokralainen-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tyylit-post-2-js": () => import("./../../../src/templates/TyylitPost2.js" /* webpackChunkName: "component---src-templates-tyylit-post-2-js" */)
}

