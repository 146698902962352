const fiText = {
  /* Lomakkeet */
  btnSend: 'Lähetä',
  btnLataaEsite: 'Lataa esite',
  wait: 'Odota...',
  sending: 'Lähetetään...',
  formError: 'Viestin lähetys epäonnistui. Yritä uudestaan!',
  downloadError: 'Jokin meni pieleen. Yritä uudestaan!',
  formSuccess: 'Kiitos, viestisi on vastaanotettu!',
  downloadEsite: 'Lataa kohde-esite',
  downloadEsiteSisustustyyli: 'Lataa sisustustyylikooste',
  /* Home Page, Tyyli ja sisustus Page */
  omaTyyli: 'Uniikki suunnittelu ja tyyli',
  /* Tilaratkaisu Page */
  pyydaTarjous: 'Pyydä tarjous',
  /* Single Blog Post Page */
  showAllArticles: 'Näytä kaikki',
  continueReading: 'Jatka lukemista',
  /* Tyylit Post Page */
  back: 'Takaisin',
  /* 404 Page */
  notification404: 'Höh, tämä sivu on tyystin hukassa... :(',
  goToHomePage: 'Mene etusivulle',
};

export default fiText;
