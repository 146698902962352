import React from 'react';
import NetlifyForm from 'react-netlify-form';
import { Heading, Text, Box, Flex, Button } from 'rebass';
import { Label, Input, Textarea, Radio, Checkbox } from '@rebass/forms';
import fiText from '../locales/fi.js';

const trigTagManager = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'formSubmission',
    formName: 'Yhteydenotto',
  });
  console.log('form sent to tag manager');
};

const Form = () => {
  return (
    <NetlifyForm name="Yhteydenotto">
      {({ loading, error, success }) => (
        <div>
          <div>
            <Box pb={1}>
              <Flex flexDirection="column" mx={-2} mb={1}>
                <Box width={1} px={2} mb={0} className="required">
                  <Input
                    required
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Nimi"
                  />
                </Box>
                <Box width={1} px={2} mb={0} className="required">
                  <Input
                    required
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Sähköposti"
                  />
                </Box>
                <Box width={1} px={2} mb={0} className="required">
                  <Input
                    required
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Puhelin"
                  />
                </Box>
                <Box width={1} px={2} mb={0} className="required">
                  <Input
                    id="yritys"
                    name="yritys"
                    type="text"
                    placeholder="Yritys"
                  />
                </Box>
                <Box width={1} px={2} mb={0}>
                  <Textarea
                    id="message"
                    name="message"
                    placeholder="Viesti"
                    color="beigeText"
                  />
                </Box>
                <Box px={2} mb={0}>
                  <Button sx={{ float: 'right', width: '100%' }} type="submit">
                    {fiText.btnSend}
                  </Button>
                </Box>
              </Flex>
            </Box>
          </div>

          {loading && (
            <Text mt={-1} textAlign="right" fontStyle="italic">
              {fiText.sending}
            </Text>
          )}
          {error && (
            <Text mt={-1} textAlign="right" fontStyle="italic">
              {fiText.formError}
            </Text>
          )}
          {success && (
            <Text mt={-1} textAlign="right" fontStyle="italic">
              {trigTagManager()}
              {fiText.formSuccess}
            </Text>
          )}
          <Text fontSize="15px" my="15px">
            Käsittelemme yhteystietojasi{' '}
            <a href="/tietosuojaseloste" target="_blank">
              <strong>Tietosuojaseloste</strong>
            </a>
            emme mukaisesti.
          </Text>
        </div>
      )}
    </NetlifyForm>
  );
};

export default Form;
