export default {
    breakpoints: ['40em', '52em', '64em'],
    fontSizes: [
      12, 15, 16, 19, 24, 32, 42, 52,
    ],
    colors: {
      blue: '#07c',
      lightgray: '#f6f6ff',
      bgRed: '#EFDEDE',
      bgBlue: '#F5F6F9',
      bgGrey: '#869DA8',
      darkBlue: '#001A27',
      roseRed: '#D0AFAA',
      gold: '#B09958',

      white: '#fff',
      blueDarkBg: '#152A38', // frontpage
      blueDark: '#001826', // footer, button, text
      gold: '#B09958',
      beigeText: '#DFD0B7', // bg on sijainti ja palvelut page
      beigeBg: '#E0D1B6',
      turquoise: '#296474',
      turguoiseLightBg: '#81ADB3', // talo page
      turquoiseTyylitBg: '#80ADB4', // Bg on Tyylit-post page
      redDarkButton: '#893D3B',
      peach: '#C4978F',
      peachBg: '#C6978D', // bg on Blogpost
    },
    variants: {
    },
    space: [
      0, 16, 24, 32, 42, 54, 68, 84
    ],
    fonts: {
      body: '"acumin-pro", sans-serif',
      heading: '"utopia-std", serif',
    },
    fontWeights: {
      heading: 600,
      body: 400,
      bold: 700,
    },
    lineHeights: {
      body: 1.42,
    },
    shadows: {
      small: '0 0 4px rgba(0, 0, 0, .125)',
      large: '0 0 24px rgba(0, 0, 0, .125)'
    },
    buttons: {
      primary: {
        width: '260px',
        fontFamily: '"utopia-std", serif',
        fontWeight: '600',
        letterSpacing: '1.5px',
        fontSize: '16px',
        textTransform: 'uppercase',
        color: '#001826',
        bg: '#fff',
        borderRadius: '0px',
        height: '66px',
        padding: 0,
      }
    },
    forms: { 
      input: {
        color: '#DFD0B7',
        padding: '10px 16px',
        margin: '6px 0',
        borderRadius: '0px',
        fontSize: '19px',
        borderColor: '#B09958',
      },
      select: {
        borderRadius: '0px',
      },
      textarea: {
        padding: '10px 16px',
        minHeight: '140px',
        margin: '10px 0',
        borderRadius: '0px',
        borderColor: '#B09958',
        fontSize: '19px',
      },
      label: {},
      radio: {},
      checkbox: {},
    },
  }