import 'typeface-inter';
import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

.lataaEsiteLightBg {
  display: none !important;
}
a.downloadLink svg {
  margin-right: 2px;
}
a.downloadLink:hover {
  text-decoration: underline;
  opacity: 0.9;
  transition: opacity 0.1s;
}

@import url("https://use.typekit.net/vsp8hup.css");

@media(max-width: 600px) {
  #tila-ja-hinta {
    border: none ;
  }
}
.lataaEsiteLightBg input {
  color: #152A38;
}

.lataaEsiteLightBg input::placeholder {
  color: #152A38;
}
.zoomIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}
.hideOnMobile {
  @media(max-width: 600px) {
    display: none !important;
  }
  
}

.heroPagesMobileTitle {
  @media(min-width: 600px) {
    display: none !important;
  }
}

.hoverOpacity:hover {
  opacity: 0.97;
}

@media(max-width: 800px) {
  .heroLogo {
      display: none;
  }
}
.heroLogo {
  display: none;
}

.neliot a {
  width: 100%;
}
.mainmenu a:hover {
  opacity: 0.8;
  transition: all 0.2s;
}
button:hover {
  opacity: 0.94;
  transition: all 0.2s;
}
html {
  scroll-behavior: smooth;
}

.tyylitSquares {
  transform: scale(1.3);
  @media(max-width: 830px) {    
    transform: scale(1);
    padding-top: 10px;
    padding-bottom: 40px;
  }
}

.kulkuvalineet div:nth-child(3) img {
  width: 60px;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: rgba(255,255,255,0.9);
}

.container:hover input ~ .checkmark {
  background-color: rgba(255,255,255,0.7);
}

.container input:checked ~ .checkmark {
  background-color: #001826;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.valitseSisustus input {
  margin-right: 10px;
}

.valitseSisustus input[type="checkbox"]:checked + span  {
  border-color: red;
  background-color:red;
}


nav a.mobileActiveLink {
  text-decoration: underline !important;
}

.activeLink span {
  border-bottom: 2px solid rgba(176, 153, 88, 1);
}

.closeBtnModal:after {
  content: 'Close';
  position: absolute;
  top: -10px;
}

.arrowBox {
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    animation: bounce 2.5s infinite;
    -webkit-animation: bounce 2.5s infinite;
   
  }
  .shadow {
    -webkit-filter: drop-shadow( 0px 0px 20px rgba(255, 255, 255, 1));
    filter: drop-shadow( 0px 0px 20px rgba(255, 255, 255, 1));  
  }
}

article img {
  width: 100%;
}

article .backButton {
  color: #001826 !important;
}

@media only screen and (min-width: 532px) {
  .styleSquarePage .tyyli > div, .omaTyyli {
    transform: scale(1.5);
  }
}

.hamburgerColorBlue nav button > div {
  background: #001826;
}

.aStretch {
  width: 100%;
}

#gatsby-focus-wrapper {
  overflow-x: hidden;
  position: relative;
}

.blogi-page a {
  width: 100%;
}

.required {
  position: relative;
}

.required:before {
  display: inline-block;
  content: '*';
  position: absolute;
  margin-top: 20px;
  margin-right: 4px;
  right: 20px;
  width: 24px;
  height: 24px;
  z-index: 10;
  color: #fff;
}

.tilalaskin ul.react-tabs__tab-list {
  display: none;
}
.tilalaskin {
  min-width: 680px;
  max-width: 720px;
  padding: 0 40px;
}

.kerrospohjatTalo .floors {
  border-color: #fff;
}

.kerrospohjatTalo .floors ul.react-tabs__tab-list {
  background: #fff;
}

.floors {
  height: 100%;
  border: 2px solid #DFD0B7;
  max-width: 904px;
  width: 100%;
  margin: 0 auto;
  border: none;

}
.floors .react-tabs__tab-panel { 
  padding-left: 0px;
  padding-right: 0px  
  padding-top: 80px;
  border: 2px solid #296474;
}

.floors ul.react-tabs__tab-list {
  width: 100%;
  float: left;
  background: #DFD0B7;
  list-style: none;
  margin: 0;
  color: #001826;
  padding-left: 0;
  border-bottom: none !important;
}

.floors li.react-tabs__tab {
  display: inline-block;
  width: 16.66%;
  text-align: center;
  bottom: 0px;
  height: 78px;
  line-height: 78px
  padding-left: 20px;
  padding-top: 0px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: "utopia-std";
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 5px;
  font-weight: bold;
  color: #001826; 
  border: 2px solid #296474;
}
.floors .react-tabs__tab:focus,
.floors .react-tabs__tab:hover {
  outline: 0;
  color: white;
  background: #296474 !important;
  transition: all 0.3s;
}
.floors .react-tabs__tab.react-tabs__tab--selected{
  background: #296474;
  color: #fff;
  border-radius: 0;
}

.timeTable td {
  border: none;
  padding: 8px 5px;
}

.timeTable table {
  border: none;
  text-align: left;
}

main a {
  font-weight: 700;
}

.mainmenu  {
  @media only screen and (max-width: 831px) {
    display: none !important;
  }
}

nav.mainmenu > a {
  display: inline-block;
  padding: 15px 30px;
  text-align: center;
  font-size: 16px;
  font-family: "utopia-std", serif;
  text-transform: uppercase;
  @media only screen and (max-width: 980px) {
    padding: 15px 15px;
  }
}

.cont_fourpoints > * , .cont_why > * {
  z-index: 1;
}

.tyyli:hover {
  transition: all 0.3s;
  background: rgba(0,24,38,0.90);
}

.tyyli:hover > div {
  transition: all 0.45s;
  background: white;
  color: rgba(0,24,38,1);
}


.tyyli:hover h3 {
  text-shadow: none;
}

body {
  background: #001826;
  font-size: 19px;
}

strong {
  font-weight: 600;
}

p {
  margin-bottom: 2rem;
}

h1, h3 {
  font-weight: 600;
  line-height: 1.0;
}

h2 {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.24;
  text-transform: uppercase;
}

h3 {
  font-family: "utopia-std";
  line-height: 1.44;
}

a {
  color: inherit
  &:hover {
    color: inherit
    }
}

a:hover {
  text-decoration: none;
}

input:focus,
textarea:focus {
  outline: none;
}

::placeholder {
  color: rgba(223,208,183,0.5);
  font-size: 19px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Bounce Animation */
@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
  }
}
@-moz-keyframes bounce {
  0%,
  100% {
    -moz-transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(-10px);
  }
}
@-o-keyframes bounce {
  0%,
  100% {
    -o-transform: translateY(0);
  }
  50% {
    -o-transform: translateY(-10px);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
`;

export default Global;
