import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Flex, Heading, Text, Box } from 'rebass';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import FormNetlify from './FormNetlify';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        datoCmsContact {
          title
          imgMap {
            fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
          contactPersonText
          huolto
          palvelupyyntUrl
          vuokranantaja
        }
        datoCmsLogot {
          logoBeige {
            url
            alt
          }
        }
      }
    `}
    render={data => (
      <Box as="footer" bg="#001826">
        <Flex
          flexDirection="column"
          maxWidth={['720px', '720px', '720px', '1200px']}
          px={[2, 2, 2, 2]}
          mx="auto"
          color="#fff"
        >
          <Flex flexWrap="wrap" pt={7}>
            <Box py={1} pb={4} width={[1, 1, 1, 5 / 12]}>
              <Link to="/">
                <img
                  src={data.datoCmsLogot.logoBeige.url}
                  alt={data.datoCmsLogot.logoBeige.alt}
                  width={240}
                />
              </Link>
            </Box>
            <Box width={[1, 1, 1, 7 / 12]}>
              <Text
                maxWidth="536px"
                color={'beigeText'}
                fontFamily={'utopia-std'}
                fontSize={4}
              >
                {data.datoCmsContact.title}
              </Text>
            </Box>
          </Flex>
          <Flex flexWrap="wrap" py={[4, 4, 4, 7]}>
            <Box mb={3} width={[1, 1, 1, 4 / 12]}>
              <Text
                fontSize={3}
                fontWeight={300}
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsContact.huolto,
                }}
              />
              <a
                href={data.datoCmsContact.palvelupyyntUrl}
                target="_blank"
                rel="noreferrer"
                css={`
                  background: #fff;
                  color: #152a38;
                  width: 280px;
                  height: 69px;
                  font-weight: 600;
                  font-family: 'utopia-std', serif;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  text-transform: uppercase;
                  justify-content: center;
                  font-size: 16px;
                  margin-top: 10px;
                  margin-bottom: 25px;
                  &:hover {
                    color: #264051;
                  }
                `}
              >
                <span>Tee palvelupyyntö</span>
              </a>
            </Box>
            <Box mb={3} width={[1, 1, 1, 4 / 12]}>
              <Text
                fontSize={3}
                fontWeight={300}
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsContact.vuokranantaja,
                }}
              />
            </Box>
            <Box mb={3} width={[1, 1, 1, 4 / 12]}>
              <Text
                fontSize={3}
                fontWeight={300}
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsContact.contactPersonText,
                }}
              />
            </Box>
          </Flex>
          <Flex flexWrap="wrap" py={[4, 4, 4, 7]}>
            <Box width={[1, 1, 1, 1 / 2]}>
              <Box maxWidth="380px" width="90%" mb={5}>
                <Img
                  fluid={data.datoCmsContact.imgMap.fluid}
                  alt={data.datoCmsContact.imgMap.alt}
                />
                <Link
                  style={{
                    textDecoration: 'underline',
                    paddingTop: '25px',
                    display: 'block',
                    opacity: 0.5,
                    fontSize: '15px',
                  }}
                  to="/tietosuojaseloste"
                >
                  Tietosuojaseloste
                </Link>
              </Box>
            </Box>
            <Box width={[1, 1, 1, 1 / 2]}>
              <FormNetlify />
            </Box>
          </Flex>
        </Flex>
      </Box>
    )}
  />
);

export default Footer;
